body {
  margin: 0;
  color: #fff;
  background-color: #d3d3d3;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  background-color: #444;
  font-family: Arial, sans-serif;
  padding-top: 100px;
  padding-bottom: 50px;
  align-items: center;
  display: grid;
}

.home {
  height: 100svh;
  margin-top: "-65px";
  padding-top: 5px;
  padding-bottom: 0px;
  font-family: Arial, sans-serif;
  background-color: #222;
  background-size: 600px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.hidden {
  opacity: 0;
  transform: translateX(-20px);
  transition: all 0.5s ease-in-out;
}

.is-visible {
  opacity: 1;
  transform: translateY(0);
  transition: all 1s ease-in-out;
}

.collapsed-menu {
  transform: translateX(-100%);
  transition: all 0.2s ease-in-out;
}

.collapsed-menu .menu-item {
  transform: translateX(-200%);
  filter: blur(5px);
  transition: all 0.1s ease-in-out;
}

.visible-menu {
  transform: translateX(0);
  transition: all 0.2s ease-in-out;
}

#full-name {
  font-size: 4em;
  font-weight: 700;
  margin: 0;
  padding: 0;
  color: #fff;
}

.profile-image {
  width: 100%;
  clip-path: circle();
  height: auto;
  display: block;
}

@media screen and (max-width: 600px) {
  .home {
    align-items: center;
    height: 100svh;
  }
  .container {
    padding-bottom: 0px;
  }
  .profile-image {
    width: 70%;
  }
  #full-name {
    font-size: 12vw;
  }
}

.rotating-words-container {
  height: 34px;
  overflow: hidden;
  line-height: 35px;
}

.rotating-words-container h5 {
  position: relative;
  animation: rotating-words 10s ease-in-out infinite;
  font-weight: bold;
}

@keyframes collapseMenu {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(20px);
  }
}

@keyframes appear {
  0% {
    background-color: #444;
  }
  100% {
    background-color: rgba(0, 0, 0, 0);
  }
}

@keyframes disappear {
  0% {
    /* It starts with a solid colour and gets  */
    background-color: rgba(0, 0, 0, 0)
  }
  100% {
    background-color: #444;
  }
}

@keyframes rotating-words {
  0% {
    top: 0;
  }
  20% {
    top: 0;
  }
  25% {
    top: -32px;
  }
  45% {
    top: -32px;
  }
  50% {
    top: -64px;
  }
  70% {
    top: -64px;
  }
  75% {
    top: -96px;
  }
  95% {
    top: -96px;
  }
  100% {
    top: -96px;
  }
}

.fade-in {
  opacity: 1;
  transition: opacity 0.6s ease-in-out;
}

.fade-out {
  opacity: 0;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}